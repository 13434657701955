var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('accordion',{staticClass:"filter-extra-settings mt-5",attrs:{"type":"slim","open-title":_vm.$t('filter_subsetting_accordion_extra'),"close-title":_vm.$t('filter_subsetting_accordion_extra')}},[_c('a-card',[_c('select-input',{directives:[{name:"hide-once",rawName:"v-hide-once",value:(_vm.settings.comments_mode === _vm.CommentsModeEnum.None),expression:"settings.comments_mode === CommentsModeEnum.None"}],staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'comments_mode',
          'options': _vm.commentsModeOptions,
          'clearable': false,
          'tariffTags': _vm.tag,
          'hasAnchor': true
        }
      }}}),(_vm.ignoreCaption)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'ignore_caption',
          'hasAnchor': true
        }
      }}}):_vm._e(),_c('number-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'take_away_reputation',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 1,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.settings,
          'key': 'take_away_xp',
          'min': 0,
          'max': Number.MAX_SAFE_INTEGER,
          'hasAnchor': true,
          'settingWrapper': {
            customiseValue: 1,
            hasFieldButton: true,
            disabledValue: 0
          }
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }