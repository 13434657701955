import { Component, Mixins } from 'vue-property-decorator'
import chatConfig from "@/mixins/chatConfig";
import chatPropertiesMixin from "@/mixins/chatPropertiesMixin";
import GroupTopicsMixin from "@/mixins/GroupTopicsMixin";
import i18n from "@/i18n/i18n";
import TriggersMixin from "@/mixins/TriggersMixin";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { IBaseModule, IFilterModule } from "@/includes/logic/Modules/types/types";
import Action from '@/components/ProjectButtons/Action'

import { AvailableButtonsTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'
import FingerDownReaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerDownReaction'
import Share from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Share'
import TriggerCall from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/TriggerCall'
import Reaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Reaction'
import PalmsReaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/PalmsReaction'
import FingerUpReaction from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerUpReaction'
import Link from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Link'
import TextButton from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text'
import UserPropRedirect from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/UserPropRedirect/index'
import { SelectInputData } from 'piramis-base-components/src/components/Pi/fields/SelectInput/types';
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { TimeUnitInputData, StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { MultiMessageEditorWithMediaData } from 'piramis-base-components/src/components/MultiMessageEditorWithMedia/types'
import { FileType } from 'piramis-base-components/src/components/File/types';

/* NEW BUTTONS */
import TriggerCallButtonBuilder from "@/components/ProjectButtons/NewButtons/TriggerCall";
import ActionButtonBuilder from "@/components/ProjectButtons/NewButtons/Action";
import LinkButtonBuilder from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link";
import ShareButtonBuilder from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share";
import FingerDownReactionButtonBuilder
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerDownReaction'
import FingerUpReactionButtonBuilder
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerUpReaction'
import PalmReactionButtonBuilder
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/PalmReaction'
import ReactionButtonBuilder
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Reaction'
import TextButtonBuilder
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/TextButton'

@Component({
  'mixins': [ chatConfig, chatPropertiesMixin ],
  'data': () => ({
    StepUnit
  })
})
export class InputSetups extends Mixins<GroupTopicsMixin, TriggersMixin>(GroupTopicsMixin, TriggersMixin) {
  defaultProp = { i18n: this.$i18n }

  get defaultConfig(): Record<string, any> | null {
    const adminTemplate = this.$store.state?.AdminTemplate
    const { chat } = this.$store.state.chatState

    if (adminTemplate && adminTemplate.runtime_config && this.$store.getters.isChatSet) {
      return adminTemplate.runtime_config[ `default_config_${ chat!.language_code }` ]
    }

    return {}
  }

  get defaultModuleConfigModel() {
    if (this.$store.state?.AdminTemplate?.runtime_config) {
      if (ModuleManagerState && ModuleManagerState.currentModule) {
        const type = ModuleManagerState.currentModule.model.type

        const config: Array<IBaseModule | IFilterModule> = this.$store.state.AdminTemplate.runtime_config?.default_modules_config?.[this.$store.state.chatState.chat?.language_code]

        if (config) {
          const module = config.find((m) => m.type === type)

          if (module) {
            return { params: module.params, config: module.config }
          }
        }
      }
    }
  }

  getForms(): Promise<Array<SelectOptionData>> {
    return new Promise((resolve) => {
      this.$store.dispatch("getForms")
          .then(() => resolve(this.$store.getters.formsSelectOptions));
    })
  }

  get triggerCallMessageEditorButton(): TriggerCall {
    return new TriggerCall({ getTriggersFn: this.resolveTriggersOptions, ...this.defaultProp })
  }

  get userPropRedirectEditorButton(): UserPropRedirect {
    return new UserPropRedirect({ getPropertiesFn: (this as any).getChatPropertiesSelectOptions, ...this.defaultProp })
  }

  get defaultMessageEditorButtons(): AvailableButtonsTypes {
    return [
      //  ...this.$store.state.chatState.chat.version >= 31000 ? [
      //      this.userPropRedirectEditorButton,
      //  ] : [],
      this.triggerCallMessageEditorButton,
      new Share(this.defaultProp),
      new Reaction(this.defaultProp),
      new PalmsReaction(this.defaultProp),
      new FingerUpReaction(this.defaultProp),
      new FingerDownReaction(this.defaultProp),
      new Link(this.defaultProp),
    ]
  }

  get filterMessageEditorButtons(): AvailableButtonsTypes {
    return [
        ...this.defaultMessageEditorButtons,
        new Action(this.defaultProp)
    ]
  }

  get newFilterEditorButtons() {
    return [
        ...this.newDefaultMessageEditorButtons,
      new ActionButtonBuilder(i18n)
    ]
  }

  get newDefaultMessageEditorButtons() {
    return [
      new TriggerCallButtonBuilder(i18n, this.resolveTriggersOptions),
      new ShareButtonBuilder(i18n),
      new ReactionButtonBuilder(i18n),
      new PalmReactionButtonBuilder(i18n),
      new FingerUpReactionButtonBuilder(i18n),
      new FingerDownReactionButtonBuilder(i18n),
      new LinkButtonBuilder(i18n),
    ]
  }

  get newMessageEditorButtons() {
    return [
        ...this.newDefaultMessageEditorButtons,
    ]
  }

  get groupUploadTarget() {
    return { target: 'target', target_id: JSON.stringify({ type: 'group', id: this.$store.state.chatState.chat?.chat_id }) }
  }

  boardUploadTarget(token:string) {
    return  { target: 'target', target_id: JSON.stringify({ type: 'board', token }) }
  }

  get stateChatConfigPath() {
    return this.$store.state.chatState.chat?.config || {}
  }

  get stateChatPath() {
    return this.$store.state.chatState.chat
  }

  get defaultMessageEditorSettingsStructure() {
    return {
      'remove_after': 0,
      'send_after': 0,
      'pin': false,
      'remove_previous': true,
      'disable_link_preview': false,
      'disable_notify': false,
      'protect_content': false,
      'topics': []
    }
  }

  get blacklistedFileButtonsByLicense() {
    if (!this.$store.getters.isChatLicenseExists || !this.$store.getters.getHaveBrand || !this.$store.getters.getHaveLicense) {
      return [ FileType.Image, FileType.Video, FileType.VideoNote, FileType.Document, FileType.Voice, FileType.Audio, FileType.Animation ]
    } else {
      return []
    }
  }

  get mediaButtonsLicenseMessage() {
    if (!this.$store.getters.isChatLicenseExists || !this.$store.getters.getHaveBrand || !this.$store.getters.getHaveLicense) {
      return this.$t('media_buttons_license_message', { chatId: this.$store.getters.chatId }).toString()
    } else {
      return undefined
    }
  }

  configInputSetup(args: FieldData): FieldData {
    if (this.defaultConfig) {
      const defaultValue = this.defaultConfig[args.key]
      if (args.defaultValue === undefined && defaultValue !== undefined) {
        args.defaultValue = defaultValue
      }
    }

    // message editors
    if ([ 'first_inactive_kick_warn1', 'first_inactive_kick_warn2', 'inactive_kick_warn1',
      'inactive_kick_warn2', 'hello_message', 'close_chat_message', 'open_chat_message',
      'admin_anti_karma_message', 'leaveMessage', 'wakeup_message',
      'admin_message', 'rules', 'facecontrol_warning_message', 'facecontrol_ban_message',
      'mute_command_text', 'ban_command_text', 'kick_command_text', 'karma_message', 'anti_karma_message',
      'admin_karma_message', 'reputation_overtake_message', 'referral_private_message', 'xp_action_message',
      'xp_action_jack_pot_message', 'start_bonus_xp_message', 'end_bonus_xp_message',
      'toprepmessage', 'bottomrepmessage', 'topapmessage', 'bottomapmessage', 'topmsg', 'bottommsg',
      'topxp', 'bottomxp', 'warning_message', 'ban_message', 'leave_member_message', 'chat_buttons_message', 'after_captcha_message',
        'new_limit_warning_message', 'edit_warning_message'
    ].includes(args.key)) {
      const multiEditorInputData: any = args as FieldData & MultiMessageEditorWithMediaData

      this.setEditorTopics(multiEditorInputData)

      multiEditorInputData.settingsStructure = this.defaultMessageEditorSettingsStructure
      multiEditorInputData.blacklistMediaButtonTypes = this.blacklistedFileButtonsByLicense
      multiEditorInputData.availableButtonsTypes = this.defaultMessageEditorButtons

      if ([ 'mute_command_text', 'ban_command_text', 'ban_message' ].includes(args.key)) {
        multiEditorInputData.availableButtonsTypes = this.filterMessageEditorButtons
      }

      if ([ 'wakeup_message', 'admin_message' ].includes(args.key)) {
        multiEditorInputData.settingsStructure = {
          'remove_after': 0,
          'pin': false,
          'disable_link_preview': false,
          'disable_notify': false,
          'remove_previous': false,
          'protect_content': false,
          'topics': [],
        }
      }

      if ([ 'chat_buttons_message' ].includes(args.key)) {
        multiEditorInputData.availableButtonsTypes = [ new TextButton(this.defaultProp) ]
      }
    }

    // select
    if ([
      'after_leave_trigger',
      'after_greeting_check_trigger',
      'after_not_first_greeting_trigger_new', 'after_not_first_greeting_trigger',
      'after_first_greeting_trigger_new', 'after_first_greeting_trigger',
      'after_greeting_trigger_new', 'after_greeting_trigger',
      'referral_not_first_private_trigger_new', 'referral_not_first_private_trigger',
      'referral_private_trigger_new', 'referral_private_trigger',
      'referral_first_private_trigger_new', 'referral_first_private_trigger'
    ].includes(args.key)) {
      const selectInputData: SelectInputData = args as SelectInputData
      if (selectInputData.options === undefined) {
        selectInputData.options = this.resolveTriggersOptions
      }
    }

    if ([ 'enter_protection_form' ].includes(args.key)) {
      const selectInputData: SelectInputData = args as SelectInputData
      if (selectInputData.options === undefined) {
        selectInputData.options = this.getForms
      }
    }

    // timeunit input
    if ([ 'ban_unverified_users_time', 'ban_inout_users_time', 'inactive_kick_interval', 'first_inactive_kick_interval',
      'new_user_limit', 'liven_up_timeout', 'admin_message_interval' ].includes(args.key)) {
      const timeUnitInputData: TimeUnitInputData = args as TimeUnitInputData
      timeUnitInputData.units = [ StepUnit.Minute, StepUnit.Hour, StepUnit.Day ]
    }

    if ([ 'reputation_min_interval', 'chat_reputation_min_interval' ].includes(args.key)) {
      const timeUnitInputData: TimeUnitInputData = args as TimeUnitInputData
      timeUnitInputData.units = [ StepUnit.Second, StepUnit.Minute, StepUnit.Hour, StepUnit.Day ]
    }

    if (args.setter === undefined) {
      args.setter = (value: any): void => {
        this.$store.commit('pi/EXEC', {
          'key': args.key,
          'fn': () => {
            this.$set(args.model, args.key as string, value)
          },
        })
      }
    }

    return args
  }

  newConfigInputSetup(args: FieldData): FieldData {
    if (this.defaultModuleConfigModel && this.defaultModuleConfigModel.params) {
      const defaultValue = this.defaultModuleConfigModel.params[args.key]

      if (args.defaultValue === undefined && defaultValue !== undefined) {
        this.$set(args, 'defaultValue', defaultValue)
      }
    }

    // message editors
    if ([
      'mute_command_text', 'ban_command_text', 'kick_command_text', 'rules', 'report_action_message', 'report_info_message',
      'first_inactive_kick_warn1', 'first_inactive_kick_warn2',
      'inactive_kick_warn1', 'inactive_kick_warn2',
      'wakeup_message', 'admin_message',
      'toprepmessage', 'bottomrepmessage', 'topapmessage', 'bottomapmessage', 'topmsg', 'bottommsg', 'topxp', 'bottomxp',
      'referral_private_message',
      'close_chat_message', 'open_chat_message', 'before_close_chat_message', 'close_messages',
      'edit_warning_message',
      'punishMessage',
      'facecontrol_warning_message',
      'reputation_overtake_message',
      'positiveRepAdminMessage', 'negativeRepAdminMessage',
      'xp_action_jack_pot_message', 'start_bonus_xp_message', 'end_bonus_xp_message',
      'rank_action_message',
      'leave_member_message',
      'after_captcha_message', 'after_fail_captcha_message',
      'hello_message',
      'chat_buttons_message', 'chatButtonsMessage',
      'votebanMessage'
    ].includes(args.key)) {
      const multiEditorInputData: any = args as FieldData & MultiMessageEditorWithMediaData

      if (!multiEditorInputData?.settingsStructure) {
        multiEditorInputData.settingsStructure = this.defaultMessageEditorSettingsStructure
      }

      if ([ 'wakeup_message', 'admin_message' ].includes(args.key)) {
        multiEditorInputData.settingsStructure = {
          'remove_after': 0,
          'pin': false,
          'disable_link_preview': false,
          'disable_notify': false,
          'remove_previous': true,
          'protect_content': false,
          'topics': []
        }
      }

      multiEditorInputData.settingWrapper = {
        customiseValue: Array.isArray(args.defaultValue) && args.defaultValue.length ? args.defaultValue : [ {
          text: '',
          buttons: [],
          attachments: [],
          ...multiEditorInputData?.settingsStructure ? multiEditorInputData?.settingsStructure : this.defaultMessageEditorSettingsStructure
        } ],
        disabledValue: [],
        buttonTitle: this.$t('customize').toString()
      }

      this.setEditorTopics(multiEditorInputData)

      multiEditorInputData.blacklistMediaButtonTypes = this.blacklistedFileButtonsByLicense
      multiEditorInputData.mediaButtonsMessage = this.mediaButtonsLicenseMessage

      if (!multiEditorInputData?.availableButtonsTypes) {
        multiEditorInputData.availableButtonsTypes = [ ...this.newMessageEditorButtons ]
      }

      if ([ 'mute_command_text', 'ban_command_text' ].includes(args.key)) {
        if (args.key === 'mute_command_text') {
          multiEditorInputData.availableButtonsTypes = [
              ...this.newDefaultMessageEditorButtons,
            new ActionButtonBuilder(i18n, this.$t('action_button_mute_command_text_init_text_button').toString())
          ]
        }
        if (args.key === 'ban_command_text') {
          multiEditorInputData.availableButtonsTypes = [
            ...this.newDefaultMessageEditorButtons,
            new ActionButtonBuilder(i18n, this.$t('action_button_ban_command_text_init_text_button').toString())
          ]
        }
      }

      if ([ 'chat_buttons_message', 'chatButtonsMessage' ].includes(args.key)) {
        multiEditorInputData.availableButtonsTypes = [ new TextButtonBuilder(this.$i18n) ]
      }
    }

    //select
    if ([
      'referral_not_first_private_trigger_new', 'referral_not_first_private_trigger',
      'referral_private_trigger_new', 'referral_private_trigger',
      'referral_first_private_trigger_new', 'referral_first_private_trigger',
        'after_leave_trigger',
      //
      'after_captcha_success_trigger', 'after_first_enter_trigger',
      'after_not_first_enter_trigger', 'after_enter_trigger',
      'after_first_captcha_success_trigger', 'after_not_first_captcha_success_trigger',
      'after_captcha_fail_trigger',

      // old ones
      'after_first_greeting_trigger', 'after_not_first_greeting_trigger', 'after_greeting_trigger',
    ].includes(args.key)) {
      const selectInputData: SelectInputData = args as SelectInputData

      if (selectInputData.options === undefined) {
        selectInputData.options = this.resolveTriggersOptions
      }
    }

    // timeunit input
    if ([ 'inactive_kick_interval', 'first_inactive_kick_interval',
      'wakeup_message_interval', 'admin_message_interval',
      'ban_inout_users_time',
      'new_user_limit',
      'ban_unverified_users_time'
    ].includes(args.key)) {
      const timeUnitInputData: TimeUnitInputData = args as TimeUnitInputData
      timeUnitInputData.units = [ StepUnit.Minute, StepUnit.Hour, StepUnit.Day ]
    }

    if ([ 'user_reputation_min_interval', 'chat_reputation_min_interval' ].includes(args.key)) {
      const timeUnitInputData: TimeUnitInputData = args as TimeUnitInputData
      timeUnitInputData.units = [ StepUnit.Second, StepUnit.Minute, StepUnit.Hour, StepUnit.Day ]
    }

    if (args.setter === undefined) {
      args.setter = (value: any): void => {
        this.$store.commit('pi/EXEC', {
          'key': args.key,
          'fn': () => {
            this.$set(args.model, args.key, value)
          }
        })
      }
    }

    return args
  }
}
