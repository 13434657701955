


















































import { InputSetups } from "@/mixins/input-setups";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, PropSync, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    SwitchInput
  }
})
export default class FilterJournal extends Mixins(InputSetups) {
  @PropSync('banField') journalBan!: boolean

  @PropSync('warnField') journalWarn!: boolean
}
