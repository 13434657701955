import Api from "@/includes/logic/Api";
import { UsersProfileInfo, UsersProfileStat } from "@/includes/types/UserProfile/types";

import { SuccessGetUser } from "piramis-base-components/src/components/Flow/logic/types/type";

export default class UserProfileApi {
  static async getUsersProfileInfo (chat_id: number, user_ids: Array<number>): Promise<{ data: UsersProfileInfo }> {
    return Api.sendRequest('tg', 'getusersprofileinfo', { chat_id, user_ids })
  }

  static async getUsersProfileStat (chat_id: number, user_ids: Array<number>, from:string, to:string): Promise<{ data: UsersProfileStat }> {
    return Api.sendRequest('tg', 'getusersprofilestat', { chat_id, user_ids, from, to })
  }

  static async getUsersInfo(chat_id: number, values: Array<number | string>): Promise<{ data: { data: Array<SuccessGetUser> } }> {
    return Api.sendRequest('tg', 'getusersinfo', { chat_id, values })
  }
}
