var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"filter-journal mt-5",attrs:{"title":_vm.$t('filter_title_journal_setting')}},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'journalBan',
        'hasAnchor': true,
      }
    }}}),(_vm.stateChatConfigPath.journal_moderation_all_ban)?_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('global_journal_ban_enabled_message'))},slot:"message"})]):_vm._e(),_c('switch-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'journalWarn',
        'hasAnchor': true,
      }
    }}}),(_vm.stateChatConfigPath.journal_moderation_all_warn)?_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('global_journal_warn_enabled_message'))},slot:"message"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }