











import UsersCondition from '@/includes/logic/Modules/models/conditions/UsersCondition/UsersCondition'
import BlackWhiteListTags from '@/components/Modules/components/BlackWhiteListTags.vue'
import UsersInfoMixin from "@/components/Modules/conditions/UsersCondition/UsersInfoMixin";
import GetUserInfoService from "@/includes/logic/Modules/GetUserInfoService";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    BlackWhiteListTags
  }
})
export default class UsersConditionValue extends Mixins(UsersInfoMixin) {
  @Prop() model!: UsersCondition

  @Prop() usersInfoService!: GetUserInfoService

  format(tag: string | null) {
    return this.formatter(this.usersInfoService.usersInfos, tag)
  }
}
