































































import PunishActionSetting from "@/components/Modules/punish-actions/components/PunishActionSetting.vue";
import CommonFilterSettings from "@/includes/logic/Modules/models/filters/CommonFilterSettings";
import { InputSetups } from "@/mixins/input-setups";

import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, VModel, Prop, Mixins } from 'vue-property-decorator';
import Placeholders from "@/mixins/placeholders/placeholders";

@Component({
  data() {
    return {
      StepUnit
    }
  },
  components: {
    PunishActionSetting,
    TimeUnitInput,
    NumberInput,
  }
})
export default class FilterNewUsersSettings extends Mixins(InputSetups, Placeholders) {
  @VModel() settings!: CommonFilterSettings

  @Prop() fieldsPrefix!: string

  get prefix() {
    if (this.fieldsPrefix) {
      return `${ this.fieldsPrefix }_`
    }

    return ''
  }
}
