import UserProfileApi from "@/includes/Api/UserProfile.api";
import store from "@/store/store";
import { errorNotification } from "@/includes/NotificationService";

import { SuccessGetUser } from "piramis-base-components/src/components/Flow/logic/types/type";

import { uniqBy } from "lodash";

export default class GetUserInfoService {
  usersInfos: Array<SuccessGetUser> = []

  isLoading = false

  getUsersInfos(users: Array<number | string>) {
    this.isLoading = true

    return UserProfileApi.getUsersInfo(store.getters.chatId, users)
        .then(({ data }) => {
          this.usersInfos = uniqBy(this.usersInfos.concat(data.data), (val) => val.info?.id);

          return data.data;
        })
        .catch(errorNotification)
        .finally(() => this.isLoading = false)
  }
}
