import GetUserInfoService from "@/includes/logic/Modules/GetUserInfoService";
import { warningNotification } from "@/includes/NotificationService";

import { SuccessGetUser } from "piramis-base-components/src/components/Flow/logic/types/type";
import { FieldData } from "piramis-base-components/src/components/Pi/types";

import { Component } from "vue-property-decorator";
import Vue from "vue";
import { cloneDeep, get, isEqual } from "lodash";

@Component
export default class UsersInfoMixin extends Vue {
  userInfoModelSetter(args: FieldData, modelSetTo: { model: any, path?: string }, currentModel: Array<any>, getUserInfoService: GetUserInfoService) {
    const pathToArray: Array<string | number> = modelSetTo?.path ? get(modelSetTo.model, modelSetTo.path) : modelSetTo.model

      if (isEqual(pathToArray, currentModel)) {
        args.model[args.key] = cloneDeep(modelSetTo.model)
      } else {
        const newValueIndex = pathToArray.findIndex((item) => !currentModel.includes(item))

        if (newValueIndex !== -1) {
          getUserInfoService.getUsersInfos([ pathToArray[newValueIndex] ])
              .then(res => {
                if (res && res.length) {
                  const uid = res[0].info.id

                  if (!currentModel.includes(uid)) {
                    pathToArray.splice(newValueIndex, 1, uid)

                    args.model[args.key] = cloneDeep(modelSetTo.model)
                  }
                } else {
                  warningNotification(this.$t('get_user_info_user_not_found').toString())
                }
              })
        } else {
          args.model[args.key] = cloneDeep(modelSetTo.model)
        }
      }
  }

  formatter(values: Array<SuccessGetUser>, value: string | null) {
    if (values && values.length) {
      const user = cloneDeep(values).find(u => u.value.toString().toLowerCase() === value
          || u.info?.login?.toLowerCase() === value?.toLowerCase()
          || u.info?.name?.toLowerCase() === value?.toLowerCase()
          || u.info?.id?.toString() === value?.toString()
      )

      if (user && user.info) {
        const { login, name, id } = user.info

        if (login || name) {
          return `${ id } (${ login ?? name })`
        }
      }
    }

    return value
  }
}
