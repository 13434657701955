























const enum FilterLayoutViews {
  main = 'main',
  new = 'new',
  edit = 'edit'
}

import FilterModule from "@/includes/logic/Modules/models/filters/FilterModule";
import FilterNewUsersSettings from "@/components/Modules/components/filters/FilterNewUsersSettings.vue";
import FilterRemoveEditedSetting from "@/components/Modules/components/filters/FilterRemoveEditedSetting.vue";

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";

import { Vue, Component, Prop } from 'vue-property-decorator';
import { EditedMessageFilterFields, NewUserFilterFields } from "@/includes/logic/Modules/types/types";

@Component({
  components: {
    FilterNewUsersSettings,
    FilterRemoveEditedSetting,
    PiTabs
  }
})
export default class FilterSettingLayout extends Vue {
  @Prop() filterModel!: FilterModule

  currentView = FilterLayoutViews.main

  get tabs(): Array<SelectOptionData> {
    return [
      ...this.$slots['tab-main'] ? [ {
        label: this.$t('filter_setting_layout_tab_main').toString(),
        value: FilterLayoutViews.main,
        icon: { name: 'settings' }
      } ]: [],
      ...this.$slots['tab-new'] ? [ {
        label: this.$t('filter_setting_layout_tab_new').toString(),
        value: FilterLayoutViews.new,
        icon: { name: 'person_add' }
      } ]: [],
      ...this.$slots['tab-edit'] ? [ {
        label: this.$t('filter_setting_layout_tab_edit').toString(),
        value: FilterLayoutViews.edit,
        icon: { name: 'edit' }
      } ]: [],
    ]
  }

  mounted() {
    const newUserFields: Array<keyof NewUserFilterFields> = [
      "new_limit_punish_action",
      "first_message_punish_action",
      "new_limit_msg_count",
      "new_limit_timeout",
    ]

    const editedMessageFields: Array<keyof EditedMessageFilterFields> = [
      "process_edit_separately",
      "remove_edited_punish_action",
      "remove_edited_timeout",
      "remove_edited_msg",
    ]

    if (this.$route.hash) {
      const cuttedHash = this.$route.hash.slice(1)

      if (newUserFields.includes(cuttedHash as keyof NewUserFilterFields)) {
        this.currentView = FilterLayoutViews.new
      } else if (editedMessageFields.includes(cuttedHash as keyof EditedMessageFilterFields)) {
        this.currentView = FilterLayoutViews.edit
      } else {
        this.currentView = FilterLayoutViews.main
      }
    }
  }
}
